import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ButtonWrapper from "../components/Button"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const SectionWrapper = styled.div`
  width: 100%;
  background: #3e5b75;
  .rowContent {
    padding: 80px 0;
    min-height: 280px;
    .divWrapper {
      margin-top: 30px;
      text-align: center;
    }
  }
  h3,
  h4 {
    font-family: "Prompt", sans-serif;
    font-weight: 300;
    color: #fff;
    margin: 0 auto;
    text-align: center;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 18px;
  }
  @media only screen and (min-width: 768px) {
    h3 {
      font-size: 30px;
    }
    h4 {
      font-size: 20px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .rowContent {
      padding: 100px 0;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
  }
`

const FooterTop = () => {
  const { footerSection } = useStaticQuery(
    graphql`
      query {
        footerSection: allContentfulFooter {
          edges {
            node {
              text1
              text2
              backgroundImageDesktop {
                fluid(maxWidth: 1920) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              backgroundImageMobile {
                fluid(maxWidth: 750) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    text1,
    text2,
    backgroundImageDesktop,
    backgroundImageMobile,
  } = footerSection.edges[0].node
  const sources = [
    backgroundImageMobile.fluid,
    {
      ...backgroundImageDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <SectionWrapper>
      <BackgroundImage Tag={`section`} fluid={sources} fadeIn={false}>
        <div className="gb-container">
          <Row className="rowContent">
            <Col span={24}>
              <h3 dangerouslySetInnerHTML={{ __html: text1 }}></h3>
              <h4 dangerouslySetInnerHTML={{ __html: text2 }}></h4>
              <div className="divWrapper">
                <ButtonWrapper
                  text={options.lineText}
                  cta={options.lineCTA}
                  path="target"
                />
              </div>
            </Col>
          </Row>
        </div>
      </BackgroundImage>
    </SectionWrapper>
  )
}

export default FooterTop
