import React, { useState } from "react"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import logo from "../../static/logo/logo-aec-brand.png"
import ButtonWrapper from "./Button"
import options from "../../config/options"
import { Row, Menu, Dropdown, Button, Popover } from "antd"
import { DownOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons"

const { SubMenu } = Menu
const HeaderWrapper = styled.header`
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transition: visibility 0.05s ease-in-out;
  background-color: transparent;
  .siteLogo {
    max-height: 60px;
    max-width: 159px;
    img {
      max-height: 60px;
      max-width: 159px;
    }
  }
  #site__navbar {
    list-style: none;
    line-height: 38px;
    border-bottom: none;
    background: transparent;
    display: none;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }
  .ctaWrapper {
    margin-left: 20px;
  }
  .mobileMenuWrapper {
    display: block;
    button {
      font-size: 18px;
      color: #fff;
      background: #ac0402;
      border-color: #ac0402;
      width: 38px;
      height: 32px;
      border: none;
      text-shadow: none;
      box-shadow: none;
      svg {
        color: #fff;
        fill: #fff;
        width: 32px;
        height: 20px;
      }
    }
  }
  @media only screen and (min-width: 576px) {
    .siteLogo {
      max-height: 65px;
      max-width: 173px;
      img {
        max-height: 65px;
        max-width: 173px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .siteLogo {
      max-height: 75px;
      max-width: 199px;
      img {
        max-height: 75px;
        max-width: 199px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    #site__navbar {
      display: flex;
    }
    .mobileMenuWrapper {
      display: none;
    }
  }
`
const LiWrapper = styled.li`
  display: inline-flex;
  align-items: center;
  border-bottom: none !important;
  max-height: 38px;
  padding: 0 10px;
  white-space: nowrap;
  a {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    &:hover {
      font-weight: 500;
      color: #0699e2;
    }
  }
  .active {
    font-weight: 500;
    color: #0699e2;
  }
`

const HeaderTransParent = () => {
  const [popVisible, setPopVisible] = useState(false)

  const subMenu1 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="1">
        <Link to="/solarcell-pump-1inch">
          ปั๊ม 1นิ้ว 550W 2แผง (รุ่นเล็กเน้นประหยัด)
        </Link>
        <Link to="/pump-02">ปั๊ม 2นิ้ว 750W 3แผง (รุ่นขายดี!เน้นประหยัด)</Link>
        <Link to="/pump-03">ปั๊ม 2นิ้ว 1100W 4แผง (รุ่นขวัญใจมหาชน)</Link>
        <Link to="/pump-04">ปั๊ม 2นิ้ว 1500W 6แผง (รุ่นใช้อัดสปริงเกอร์)</Link>
        <Link to="/pump-05">ปั๊ม 3นิ้ว 1500W 6แผง (รุ่นใหญ่!ได้น้ำเยอะ)</Link>
        <Link to="/pump-06">ปั๊ม 4นิ้ว 2200W 8แผง (รุ่นใหญ่!ได้น้ำเยอะ)</Link>
        <Link to="/pump-07">ปั๊ม 2” ACDC 1100W 4แผง (ใช้ไฟได้2ระบบ)</Link>
        <Link to="/pump-08">ปั๊ม 2” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)</Link>
        <Link to="/pump-09">ปั๊ม 3” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)</Link>
        <Link to="/pump-10">ปั๊ม 4” ACDC 2200W 8แผง (ใช้ไฟได้2ระบบ)</Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu2 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="2">
        <Link to="/submersible-01">
          ปั๊ม 1.5นิ้ว 600W 2แผง (รุ่นเล็กเน้นประหยัด)
        </Link>
        <Link to="/submersible-02">
          ปั๊ม 2นิ้ว 750W 3แผง (รุ่นขายดีเน้นประหยัด)
        </Link>
        <Link to="/submersible-03">
          ปั๊ม 2นิ้ว 1100W 4แผง (รุ่นขวัญใจมหาชน)
        </Link>
        <Link to="/submersible-04">
          ปั๊ม 2นิ้ว 1500W 6แผง (รุ่นขายดีได้น้ำเยอะ)
        </Link>
        <Link to="/submersible-05">
          ปั๊ม 1.5นิ้ว 2200W 8แผง (รุ่นเฮดสูงใช้บ่อลึก)
        </Link>
        <Link to="/submersible-06">
          ปั๊ม 2” ACDC 1100W 4แผง (ใช้ไฟได้2ระบบ)
        </Link>
        <Link to="/submersible-07">
          ปั๊ม 2” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)
        </Link>
        <Link to="/submersible-08">
          ปั๊ม 3” ACDC 2200W 8แผง (รุ่นใหญ่น้ำเยอะ)
        </Link>
        <Link to="/submersible-09">
          ปั๊ม 3” ACDC 3000W 8แผง (รุ่นใหญ่น้ำเยอะ)
        </Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu3 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="3">
        <Link to="/airsolarcell1">
          แอร์โซล่าเซลล์ 13,000btu รุ่นประหยัด 2แผง
        </Link>
        <Link to="/airsolarcell2">
          แอร์โซล่าเซลล์ 13,000btu รุ่นท็อปสุด 3แผง
        </Link>
        <Link to="/airsolarcell3">
          แอร์โซล่าเซลล์ 19,000btu รุ่นประหยัด 4แผง
        </Link>
        <Link to="/airsolarcell4">
          แอร์โซล่าเซลล์ 19,000btu รุ่นท็อปสุด 5แผง
        </Link>
        <Link to="/airsolarcell5">
          แอร์โซล่าเซลล์ 25,000btu รุ่นประหยัด 5แผง
        </Link>
        <Link to="/airsolarcell6">
          แอร์โซล่าเซลล์ 25,000btu รุ่นท็อปสุด 6แผง
        </Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu4 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="4">
        <Link to="/install1">วิธีติดตั้ง แอร์โซล่าเซลล์</Link>
        <Link to="/install2">วิธีติดตั้ง ปั๊มบาดาลโซล่าเซลล์</Link>
        <Link to="/install3">วิธีติดตั้ง ปั๊มหอยโข่งโซล่าเซลล์</Link>
        <Link to="/install4">วิธีติดตั้ง ขาตั้งแผงโซล่าเซลล์</Link>
        <Link to="/install5">วิธีติดตั้ง แผงโซล่าเซลล์บนหลังคา</Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu5 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="5">
        <Link to="/contact-us/">สอบถาม-สั่งซื้อ</Link>
        <Link to="/company/">ประวัติบริษัท และโรงงาน</Link>
      </Menu.Item>
    </Menu>
  )
  const mobileMenu = (
    <Menu mode="inline" defaultSelectedKeys={["sub1"]}>
      <SubMenu key="sub1" title="ปั๊มหอยโข่งโซล่าเซลล์">
        <Menu.Item key="11">
          <Link to="/solarcell-pump-1inch">
            ปั๊ม 1นิ้ว 550W 2แผง (รุ่นเล็กเน้นประหยัด)
          </Link>
        </Menu.Item>
        <Menu.Item key="12">
          <Link to="/pump-02">
            ปั๊ม 2นิ้ว 750W 3แผง (รุ่นขายดี!เน้นประหยัด)
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to="/pump-03">ปั๊ม 2นิ้ว 1100W 4แผง (รุ่นขวัญใจมหาชน)</Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to="/pump-04">
            ปั๊ม 2นิ้ว 1500W 6แผง (รุ่นใช้อัดสปริงเกอร์)
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to="/pump-05">ปั๊ม 3นิ้ว 1500W 6แผง (รุ่นใหญ่!ได้น้ำเยอะ)</Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link to="/pump-06">ปั๊ม 4นิ้ว 2200W 8แผง (รุ่นใหญ่!ได้น้ำเยอะ)</Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to="/pump-07">ปั๊ม 2” ACDC 1100W 4แผง (ใช้ไฟได้2ระบบ)</Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to="/pump-08">ปั๊ม 2” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)</Link>
        </Menu.Item>
        <Menu.Item key="18">
          <Link to="/pump-09">ปั๊ม 3” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)</Link>
        </Menu.Item>
        <Menu.Item key="19">
          <Link to="/pump-10">ปั๊ม 4” ACDC 2200W 8แผง (ใช้ไฟได้2ระบบ)</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub2" title="ปั๊มบาดาลโซล่าเซลล์">
        <Menu.Item key="21">
          <Link to="/submersible-01">
            ปั๊ม 1.5นิ้ว 600W 2แผง (รุ่นเล็กเน้นประหยัด)
          </Link>
        </Menu.Item>
        <Menu.Item key="22">
          <Link to="/submersible-02">
            ปั๊ม 2นิ้ว 750W 3แผง (รุ่นขายดีเน้นประหยัด)
          </Link>
        </Menu.Item>
        <Menu.Item key="23">
          <Link to="/submersible-03">
            ปั๊ม 2นิ้ว 1100W 4แผง (รุ่นขวัญใจมหาชน)
          </Link>
        </Menu.Item>
        <Menu.Item key="24">
          <Link to="/submersible-04">
            ปั๊ม 2นิ้ว 1500W 6แผง (รุ่นขายดีได้น้ำเยอะ)
          </Link>
        </Menu.Item>
        <Menu.Item key="25">
          <Link to="/submersible-05">
            ปั๊ม 1.5นิ้ว 2200W 8แผง (รุ่นเฮดสูงใช้บ่อลึก)
          </Link>
        </Menu.Item>
        <Menu.Item key="26">
          <Link to="/submersible-06">
            ปั๊ม 2” ACDC 1100W 4แผง (ใช้ไฟได้2ระบบ)
          </Link>
        </Menu.Item>
        <Menu.Item key="27">
          <Link to="/submersible-07">
            ปั๊ม 2” ACDC 1500W 6แผง (ใช้ไฟได้2ระบบ)
          </Link>
        </Menu.Item>
        <Menu.Item key="28">
          <Link to="/submersible-08">
            ปั๊ม 3” ACDC 2200W 8แผง (รุ่นใหญ่น้ำเยอะ)
          </Link>
        </Menu.Item>
        <Menu.Item key="29">
          <Link to="/submersible-09">
            ปั๊ม 3” ACDC 3000W 8แผง (รุ่นใหญ่น้ำเยอะ)
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub3" title="แอร์โซล่าเซลล์">
        <Menu.Item key="31">
          <Link to="/airsolarcell1">
            แอร์โซล่าเซลล์ 13,000btu รุ่นประหยัด 2แผง
          </Link>
        </Menu.Item>
        <Menu.Item key="32">
          <Link to="/airsolarcell2">
            แอร์โซล่าเซลล์ 13,000btu รุ่นท็อปสุด 3แผง
          </Link>
        </Menu.Item>
        <Menu.Item key="33">
          <Link to="/airsolarcell3">
            แอร์โซล่าเซลล์ 19,000btu รุ่นประหยัด 4แผง
          </Link>
        </Menu.Item>
        <Menu.Item key="34">
          <Link to="/airsolarcell4">
            แอร์โซล่าเซลล์ 19,000btu รุ่นท็อปสุด 5แผง
          </Link>
        </Menu.Item>
        <Menu.Item key="35">
          <Link to="/airsolarcell5">
            แอร์โซล่าเซลล์ 25,000btu รุ่นประหยัด 5แผง
          </Link>
        </Menu.Item>
        <Menu.Item key="36">
          <Link to="/airsolarcell6">
            แอร์โซล่าเซลล์ 25,000btu รุ่นท็อปสุด 6แผง
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub4" title="วิธีติดตั้ง">
        <Menu.Item key="41">
          <Link to="/install1">วิธีติดตั้ง แอร์โซล่าเซลล์</Link>
        </Menu.Item>
        <Menu.Item key="42">
          <Link to="/install2">วิธีติดตั้ง ปั๊มบาดาลโซล่าเซลล์</Link>
        </Menu.Item>
        <Menu.Item key="43">
          <Link to="/install3">วิธีติดตั้ง ปั๊มหอยโข่งโซล่าเซลล์</Link>
        </Menu.Item>
        <Menu.Item key="44">
          <Link to="/install4">วิธีติดตั้ง ขาตั้งแผงโซล่าเซลล์</Link>
        </Menu.Item>
        <Menu.Item key="45">
          <Link to="/install5">วิธีติดตั้ง แผงโซล่าเซลล์บนหลังคา</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub5" title="ติดต่อเรา">
        <Menu.Item key="51">
          <Link to="/contact-us/">สอบถาม-สั่งซื้อ</Link>
        </Menu.Item>
        <Menu.Item key="52">
          <Link to="/company/">ประวัติบริษัท และโรงงาน</Link>
        </Menu.Item>
        {/* <Menu.Item key="53">
          <Link to="https://www.youtube.com/@solarcell">
            คลิป Youtube สอนติดตั้งโซล่าเซลล์ ด้วยตัวเอง
          </Link>
        </Menu.Item>
        <Menu.Item key="54">
          <Link to="https://youtu.be/fj2xZurBHnE">
            พี่หมีบริจาคโซล่าเซลล์ และช่วยลดโลกร้อน CO2
          </Link>
        </Menu.Item> */}
      </SubMenu>
      <div
        style={{
          height: "44px",
          lineHeight: "44px",
          paddingLeft: "30px",
          marginTop: "25px",
        }}
      >
        <ButtonWrapper
          text={options.telText}
          cta={options.telCTA}
          path="target"
          icon="phone"
        />
      </div>
    </Menu>
  )

  const handleVisibleChange = visible => {
    setPopVisible(visible)
  }

  return (
    <ThemeContext.Consumer>
      {() => (
        <div id="site__header__wrapper">
          <HeaderWrapper
            id="site__header"
            style={{ backgroundColor: popVisible ? `#ac0402` : `#ac0402` }}
          >
            <div className="gb-container">
              <Row justify="space-between" align="middle">
                <Link to="/" className="siteLogo" aria-label="Site logo">
                  <img src={logo} width="201" height="75" alt="Site logo" />
                </Link>
                <ul id="site__navbar">
                  <LiWrapper key="home">
                    <Link activeClassName="active" to="/">
                      หน้าแรก
                    </Link>
                  </LiWrapper>
                  <LiWrapper key="subMenu1">
                    <Dropdown overlay={subMenu1}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        ปั๊มหอยโข่งโซล่าเซลล์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu2">
                    <Dropdown overlay={subMenu2}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        ปั๊มบาดาลโซล่าเซลล์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu3">
                    <Dropdown overlay={subMenu3}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        แอร์โซล่าเซลล์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu4">
                    <Dropdown overlay={subMenu4}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        วิธีติดตั้ง <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu5">
                    <Dropdown overlay={subMenu5}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        ติดต่อเรา <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <li key="cta" className="ctaWrapper">
                    <ButtonWrapper
                      text={options.telText}
                      cta={options.telCTA}
                      path="target"
                      icon="phone"
                    />
                  </li>
                </ul>
                <div className="mobileMenuWrapper">
                  <Popover
                    placement="bottomRight"
                    content={mobileMenu}
                    trigger="click"
                    visible={popVisible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      type="primary"
                      icon={popVisible ? <CloseOutlined /> : <MenuOutlined />}
                      onClick={handleVisibleChange}
                    />
                  </Popover>
                </div>
              </Row>
            </div>
          </HeaderWrapper>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default HeaderTransParent
